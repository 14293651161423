<template>
  <div>

    <template v-if="mainColor == '活潑'">
      <div class="game2">
        <div :class="[isDrawing ? 'rouletteImage spin' : 'stop']" ref="rouletteImage" class="rouletteImage" style="overflow: hidden;">
          <img class="item-roulette" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_item_1.png" alt="輪盤圖" />
          <div :class="'prize-' + (index)" v-for="(item, index) in this.prizeData" :key="item.id">
            <h5 class='m-0'>{{item.prizeName}}</h5>
            <img :src="item.prizeImage" style="width: 48%;" alt="獎項圖片">
          </div>
        </div>
        <template v-if="this.centerImagePath != ''">
          <div class="centerImage">
            <img :src="this.centerImagePath" alt="輪盤中心圖片">
          </div>
        </template>
      </div>
      <img class="item-pointer" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_1.png" alt="輪盤指針" />
    </template>

    <template v-else-if="mainColor == '有趣'">
      <div class="game2">
        <div :class="[isDrawing ? 'rouletteImage spin' : 'stop']" ref="rouletteImage" class="rouletteImage">
          <img class="item-roulette" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_item_2.png" alt="輪盤圖" />
          <div :class="'prize-' + (index)" v-for="(item, index) in this.prizeData" :key="item.id">
            <h5 class='m-0'>{{item.prizeName}}</h5>
            <img :src="item.prizeImage" style="width: 48%;" alt="獎項圖片">
          </div>
        </div>
        <template v-if="this.centerImagePath != ''">
          <div class="centerImage">
            <img :src="this.centerImagePath" alt="輪盤中心圖片">
          </div>
        </template>
      </div>
      <img class="item-pointer" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_2.png" alt="輪盤指針" />
    </template>

    <template v-else-if="mainColor == '喜慶'">
      <div class="game2">
        <div :class="[isDrawing ? 'rouletteImage spin' : 'stop']" ref="rouletteImage" class="rouletteImage">
          <img class="item-roulette" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_item_3.png" alt="輪盤圖" />
          <div :class="'prize-' + (index)" v-for="(item, index) in this.prizeData" :key="item.id">
            <h5 class='m-0'>{{item.prizeName}}</h5>
            <img :src="item.prizeImage" style="width: 48%;" alt="獎項圖片">
          </div>
        </div>
        <template v-if="this.centerImagePath != ''">
          <div class="centerImage">
            <img :src="this.centerImagePath" alt="輪盤中心圖片">
          </div>
        </template>
      </div>
      <img class="item-pointer" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_3.png" alt="輪盤指針" />
    </template>
    <template v-else-if="mainColor == '夏日'">
      <div class="game2">
        <div :class="[isDrawing ? 'rouletteImage spin' : 'stop']" ref="rouletteImage" class="rouletteImage">
          <img class="item-roulette" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_item_4.png" alt="輪盤圖" />
          <div :class="'prize-' + (index)" v-for="(item, index) in this.prizeData" :key="item.id">
            <h5 class='m-0'>{{item.prizeName}}</h5>
            <img :src="item.prizeImage" style="width: 48%;" alt="獎項圖片">
          </div>
        </div>
        <template v-if="this.centerImagePath != ''">
          <div class="centerImage">
            <img :src="this.centerImagePath" alt="輪盤中心圖片">
          </div>
        </template>
      </div>
      <img class="item-pointer" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_4.png" alt="輪盤指針" />
    </template>
    <template v-else-if="mainColor == '舒適'">
      <div class="game2">
        <div :class="[isDrawing ? 'rouletteImage spin' : 'stop']" ref="rouletteImage" class="rouletteImage">
          <img class="item-roulette" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_item_5.png" alt="輪盤圖" />
          <div :class="'prize-' + (index)" v-for="(item, index) in this.prizeData" :key="item.id">
            <h5 class='m-0'>{{item.prizeName}}</h5>
            <img :src="item.prizeImage" style="width: 48%;" alt="獎項圖片">
          </div>
        </div>
        <template v-if="this.centerImagePath != ''">
          <div class="centerImage">
            <img :src="this.centerImagePath" alt="輪盤中心圖片">
          </div>
        </template>
      </div>
      <img class="item-pointer" src="https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_5.png" alt="輪盤指針" />
    </template>


  </div>
</template>

<script>
export default {
  data() {
    return {
      prizeData: [],
      getPlayPrize: {}
    }
  },
  props: [ 'isDrawing', 'mainColor', 'centerImagePath', 'gamePrizes', 'playGamePrize'],
  created() {
    this.previewPrize();
  },
  watch: {
    playGamePrize(n) {
      if (!n.prizeIndex) {
        n.prizeIndex = 0
      }
      if (n && n.prizeIndex != undefined) {
        this.stopAnimation(n.prizeIndex);
      }
    }
  },
  methods: {
    previewPrize() {
      let data = []
      let couponItem = {
        prizeName: '中獎',
        prizeImage: 'https://storage.googleapis.com/c360plus/game/game2_element/game2_coupon.png'
      }
      let LinePointItem = {
        prizeName: '中獎',
        prizeImage: 'https://storage.googleapis.com/c360plus/game/game2_element/game2_LINEPOINTS.png'
      }
      let loseItem = {
        prizeName: '未中獎',
        prizeImage: 'https://storage.googleapis.com/c360plus/game/game2_element/game2_losePrize.png'
      }

      for (let i = 0; i < this.gamePrizes.length; i++) {
        if (this.gamePrizes[i].prizeType == 'LINE POINTS'){
          LinePointItem.prizeName = this.gamePrizes[i].prizeName
          LinePointItem.prizeIndex = this.gamePrizes[i].prizeIndex
          const newLinePointItem = { ...LinePointItem }
          data.push(newLinePointItem)
        }
        if (this.gamePrizes[i].prizeType == '優惠券'){
          couponItem.prizeName = this.gamePrizes[i].prizeName
          couponItem.prizeIndex = this.gamePrizes[i].prizeIndex
          const newCouponItem = { ...couponItem }
          data.push(newCouponItem)
        }
      }

      let map = new Map()
      map.set(1, [ 0, 'lose', 0, 'lose', 0, 'lose' ])
      map.set(2, [ 0, 1, 'lose', 0, 1, 'lose' ])
      map.set(3, [ 0, 'lose', 1, 'lose', 2, 'lose' ])
      map.set(4, [ 0, 1, 'lose', 2, 'lose', 3 ])
      map.set(5, [ 0, 1, 2, 3, 4, 'lose' ])
      map.set(6, [ 0, 1, 2, 3, 4, 5 ])

      const prizeMap = map.get(this.gamePrizes.length) || [];
      for (let i = 0; i < prizeMap.length; i++) {
        this.prizeData[i] = prizeMap[i] == 'lose' ? loseItem : data[prizeMap[i]];
        if (prizeMap[i] == 'lose') {
          this.prizeData[i].prizeIndex = 0;
        }
      }
    },

    stopAnimation (prizeIndex) {
      // 找出對應的陣列索引
      const prizeArrayIndex = this.prizeData.findIndex(
        (prize) => Number(prize.prizeIndex) == prizeIndex
      );
      const finalAngle = 720 - prizeArrayIndex * 60;
      const roulette = this.$refs.rouletteImage;
      // 第二個動畫
      roulette.classList.remove("spin");
      roulette.style.setProperty("--final-angle", `${finalAngle}deg`);
      roulette.classList.add("stop");
    }
  },
}
</script>

<style scoped>
  @keyframes rotate {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(1800deg);}
  }

  @keyframes rotateToPrize {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(var(--final-angle));}
  }

  .rouletteImage.spin {
    animation: rotate 1s linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  .rouletteImage.stop {
    animation: rotateToPrize 1s linear;
    animation-fill-mode: forwards;
  }


  .rouletteImage {
    position: relative;
  }
  .game2 {
    position: absolute;
    top: 31%;
    left: 6%;
    right: 6%;
    overflow: hidden;
  }
  .item-roulette {
    position: relative;
    width: 100%;
  }
  .game2 h5 {
    font-size: 18px;
  }
  .prize-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 12%;
    left: 32%;
  }
  .prize-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 26%;
    left: 55%;
    transform: rotate(60deg);
  }
  .prize-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 51%;
    left: 54%;
    transform: rotate(120deg);
  }
  .prize-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 64%;
    left: 32%;
    transform: rotate(180deg);
  }
  .prize-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 51%;
    left: 10%;
    transform: rotate(240deg);
  }
  .prize-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 36%;
    top: 26%;
    left: 10%;
    transform: rotate(300deg);
  }
  .item-pointer {
    width: 10%;
    position: absolute;
    top: 28%;
    left: 45.5%;
  }
  .centerImage {
    width: 17%;
    height: 17%;
    position: absolute;
    z-index: 5;
    left: 41.5%;
    top: 41.5%;
  }
  .centerImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 53.2px;
  }
  @media screen and (min-width: 430px) {
    .game2 h5 {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 500px) {
    .game2 h5 {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 600px) {
    .game2 h5 {
      font-size: 28px;
    }
  }
  @media screen and (min-width: 700px) {
    .game2 h5 {
      font-size: 30px;
    }
  }
</style>
