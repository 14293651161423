var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mainColor == "活潑"
        ? [
            _c(
              "div",
              { staticClass: "game2" },
              [
                _c(
                  "div",
                  {
                    ref: "rouletteImage",
                    staticClass: "rouletteImage",
                    class: [_vm.isDrawing ? "rouletteImage spin" : "stop"],
                    staticStyle: { overflow: "hidden" },
                  },
                  [
                    _c("img", {
                      staticClass: "item-roulette",
                      attrs: {
                        src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_item_1.png",
                        alt: "輪盤圖",
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(this.prizeData, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, class: "prize-" + index },
                        [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(item.prizeName)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "48%" },
                            attrs: { src: item.prizeImage, alt: "獎項圖片" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                this.centerImagePath != ""
                  ? [
                      _c("div", { staticClass: "centerImage" }, [
                        _c("img", {
                          attrs: {
                            src: this.centerImagePath,
                            alt: "輪盤中心圖片",
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-pointer",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_1.png",
                alt: "輪盤指針",
              },
            }),
          ]
        : _vm.mainColor == "有趣"
        ? [
            _c(
              "div",
              { staticClass: "game2" },
              [
                _c(
                  "div",
                  {
                    ref: "rouletteImage",
                    staticClass: "rouletteImage",
                    class: [_vm.isDrawing ? "rouletteImage spin" : "stop"],
                  },
                  [
                    _c("img", {
                      staticClass: "item-roulette",
                      attrs: {
                        src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_item_2.png",
                        alt: "輪盤圖",
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(this.prizeData, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, class: "prize-" + index },
                        [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(item.prizeName)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "48%" },
                            attrs: { src: item.prizeImage, alt: "獎項圖片" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                this.centerImagePath != ""
                  ? [
                      _c("div", { staticClass: "centerImage" }, [
                        _c("img", {
                          attrs: {
                            src: this.centerImagePath,
                            alt: "輪盤中心圖片",
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-pointer",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_2.png",
                alt: "輪盤指針",
              },
            }),
          ]
        : _vm.mainColor == "喜慶"
        ? [
            _c(
              "div",
              { staticClass: "game2" },
              [
                _c(
                  "div",
                  {
                    ref: "rouletteImage",
                    staticClass: "rouletteImage",
                    class: [_vm.isDrawing ? "rouletteImage spin" : "stop"],
                  },
                  [
                    _c("img", {
                      staticClass: "item-roulette",
                      attrs: {
                        src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_item_3.png",
                        alt: "輪盤圖",
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(this.prizeData, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, class: "prize-" + index },
                        [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(item.prizeName)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "48%" },
                            attrs: { src: item.prizeImage, alt: "獎項圖片" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                this.centerImagePath != ""
                  ? [
                      _c("div", { staticClass: "centerImage" }, [
                        _c("img", {
                          attrs: {
                            src: this.centerImagePath,
                            alt: "輪盤中心圖片",
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-pointer",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_3.png",
                alt: "輪盤指針",
              },
            }),
          ]
        : _vm.mainColor == "夏日"
        ? [
            _c(
              "div",
              { staticClass: "game2" },
              [
                _c(
                  "div",
                  {
                    ref: "rouletteImage",
                    staticClass: "rouletteImage",
                    class: [_vm.isDrawing ? "rouletteImage spin" : "stop"],
                  },
                  [
                    _c("img", {
                      staticClass: "item-roulette",
                      attrs: {
                        src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_item_4.png",
                        alt: "輪盤圖",
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(this.prizeData, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, class: "prize-" + index },
                        [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(item.prizeName)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "48%" },
                            attrs: { src: item.prizeImage, alt: "獎項圖片" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                this.centerImagePath != ""
                  ? [
                      _c("div", { staticClass: "centerImage" }, [
                        _c("img", {
                          attrs: {
                            src: this.centerImagePath,
                            alt: "輪盤中心圖片",
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-pointer",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_4.png",
                alt: "輪盤指針",
              },
            }),
          ]
        : _vm.mainColor == "舒適"
        ? [
            _c(
              "div",
              { staticClass: "game2" },
              [
                _c(
                  "div",
                  {
                    ref: "rouletteImage",
                    staticClass: "rouletteImage",
                    class: [_vm.isDrawing ? "rouletteImage spin" : "stop"],
                  },
                  [
                    _c("img", {
                      staticClass: "item-roulette",
                      attrs: {
                        src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_item_5.png",
                        alt: "輪盤圖",
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(this.prizeData, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, class: "prize-" + index },
                        [
                          _c("h5", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(item.prizeName)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { width: "48%" },
                            attrs: { src: item.prizeImage, alt: "獎項圖片" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                this.centerImagePath != ""
                  ? [
                      _c("div", { staticClass: "centerImage" }, [
                        _c("img", {
                          attrs: {
                            src: this.centerImagePath,
                            alt: "輪盤中心圖片",
                          },
                        }),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-pointer",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game2_element/game2_pointer_5.png",
                alt: "輪盤指針",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }